import React from "react";
import "../App.css";
import Card from "../components/Card";
import "./Projects.css";
import {
  cProgrammingLogo,
  cPlusPlusLogo,
  cmakeLogo,
  wxWidgetsLogo,
  javascriptLogo,
  swiftLogo,
  gitIcon,
  reactLogo,
  htmlLogo,
  cssLogo,
} from "../helpers/icons";

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const Projects = () => {
  return (
    <>
      <div className="project-page">
        <div className="project-hero">
          <p>Projects</p>
        </div>
        <div className="cards-container">
          <Card
            title="Stop-motion animator"
            descrip="A simple app for making stop-motion animations"
            githubLink="https://github.com/benITo47/gfk-stop-motion"
            imageSrc="assets/Stop-motion-proj-picture.png"
            stackIcons={[
              { src: cPlusPlusLogo },
              { src: gitIcon },
              { src: wxWidgetsLogo },
              { src: cmakeLogo },
            ]}
          />
          <Card
            title="TBA"
            descrip="TO BE BUILD - AFTER THE WEBPAGE"
            githubLink="https://github.com/your-repo"
            imageSrc="assets/proj2.png"
            stackIcons={[
              { src: javascriptLogo },
              { src: reactLogo },
              { src: cssLogo },
              { src: htmlLogo },
            ]}
          />
          <Card
            title="Dummy-project"
            descrip="A simple app for making stop-motion animations"
            githubLink="https://github.com/benITo47/gfk-stop-motion"
            imageSrc="assets/proj1.jpg"
            stackIcons={[
              { src: cPlusPlusLogo },
              { src: gitIcon },
              { src: wxWidgetsLogo },
              { src: cmakeLogo },
            ]}
          />
          <Card
            title="TBA"
            descrip="TO BE BUILD - AFTER THE WEBPAGE"
            githubLink="https://github.com/your-repo"
            imageSrc="assets/Stop-motion-proj-picture.png"
            stackIcons={[
              { src: cPlusPlusLogo },
              { src: gitIcon },
              { src: wxWidgetsLogo },
              { src: cmakeLogo },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Projects;
