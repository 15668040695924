import React, { useRef, useEffect } from "react";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";
import "./Card.css";

const Card = ({
  title = "Default Title",
  descrip = "This is a default description.",
  githubLink = "#",
  imageSrc = "/path/to/default-image.png",
  stackIcons = [], // Default to an empty array if not provided
}) => {
  const cardRef = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const transform = useMotionTemplate`rotateX(${x}deg) rotateY(${y}deg)`;

  const handleMouseMove = (e) => {
    if (!cardRef.current) return [0, 0];

    const rect = cardRef.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * 30;
    const mouseY = (e.clientY - rect.top) * 30;

    const rX = (mouseY / height - 15) * -1;
    const rY = mouseX / width - 15;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      className="card"
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ transform }}
    >
      <div className="project-title">
        <h1>{title}</h1>
      </div>
      <div className="project-picture">
        <img src={imageSrc} alt="Project Display" />
      </div>
      <div className="description-content">{descrip}</div>
      <div className="bottom-section">
        <div className="stack-icons">
          {stackIcons.map((icon, index) => (
            <a
              key={index}
              href={icon.link}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link"
            >
              <img src={icon.src} alt={`Tech stack icon ${index + 1}`} />
            </a>
          ))}
        </div>
        <div className="project-links">
          <a href={githubLink} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
