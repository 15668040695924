// icons.js
export const cProgrammingLogo =
  "https://upload.wikimedia.org/wikipedia/commons/1/18/C_Programming_Language.svg";
export const cPlusPlusLogo =
  "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg";
export const cmakeLogo =
  "https://upload.wikimedia.org/wikipedia/commons/1/13/Cmake.svg";
export const wxWidgetsLogo =
  "https://www.wxwidgets.org/downloads/logos/blocks.svg";
export const javascriptLogo =
  "https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg";
export const swiftLogo = "https://www.svgrepo.com/show/374112/swift.svg";
export const gitIcon =
  "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.svg";
export const reactLogo =
  " https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg";
export const htmlLogo =
  "https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg";
export const cssLogo =
  "https://upload.wikimedia.org/wikipedia/commons/3/3d/CSS.3.svg";
