import React from "react";
import { motion } from "framer-motion";
import "../App.css";
import "./Contact.css";
import { ReactTyped } from "react-typed";

const socialMediaLinks = [
  { name: "github", link: "https://github.com/benito47", icon: "fa fa-github" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/bart%C5%82omiej-obrochta-b48392290/",
    icon: "fa fa-linkedin",
  },
  {
    name: "mail",
    link: "mailto:bartlomiej.obrochta03@gmail.com",
    icon: "fa fa-envelope",
  },
  { name: "twitter", link: "https://twitter.com", icon: "fa fa-twitter" },
];

const Contact = () => {
  return (
    <>
      <div className="contact-page">
        <div className="contact-info">
          <motion.div className="contact-title">
            <ReactTyped
              strings={["Contact"]}
              typeSpeed={100}
              loop={false}
              showCursor={true}
              onComplete={(self) => {
                self.cursor.remove();
              }}
            />
          </motion.div>

          <motion.div
            className="contact-description"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.001 }}
          >
            <ReactTyped
              strings={["Feel free to reach out to me here "]}
              showCursor={true}
              typeSpeed={40}
              startDelay={1000}
            />
          </motion.div>
        </div>
        <div className="contact-decoration">
          <div className="contact-icons">
            {socialMediaLinks.map((social, index) => (
              <motion.div
                key={social.name}
                className="socials"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 3.5 + index * 0.5 }}
              >
                <a href={social.link} target="_blank" rel="noopener noreferrer">
                  <i className={`${social.icon} icon-style`}></i>
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
