import React from "react";
import "./Window.css";
import PropTypes from "prop-types";

const Window = ({ title, children = "content" }) => {
  return (
    <div className={`window `}>
      <div className="window-title">
        <h1>{title}</h1>
      </div>
      <div className="window-content">{children}</div>
    </div>
  );
};

Window.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default Window;
