import React from "react";
import { motion } from "framer-motion";
import "./Home.css";
import { ReactTyped } from "react-typed";
const Home = () => {
  return (
    <div className="container">
      <motion.div
        className="line"
        initial={{ width: "0%", y: 0, boxShadow: "0 0 0px #f2cdcd" }}
        animate={{
          width: ["0%", "100%"],
          y: "35vh",
          backgroundColor: ["#f2cdcd", "#111111"],
          boxShadow: ["0 0 20px #fab387", "0 0 0px #1e1e2e"],
        }}
        transition={{
          width: { duration: 2, ease: "easeOut" },
          y: { delay: 2, duration: 2 },
          backgroundColor: { delay: 2.2, duration: 2, ease: "easeOut" },
          boxShadow: { delay: 2, duration: 2, ease: "easeOut" },
        }}
      ></motion.div>
      <motion.div
        className="line"
        initial={{ width: "0%", y: 0, boxShadow: "0 0 0px #f2cdcd" }}
        animate={{
          width: ["0%", "100%"],
          y: "-35vh",
          backgroundColor: ["#f2cdcd", "#111111"],
          boxShadow: ["0 0 20px #fab387", "0 0 0px #1e1e2e"],
        }}
        transition={{
          width: { duration: 2, ease: "easeOut" },
          y: { delay: 2, duration: 2 },
          backgroundColor: { delay: 2.2, duration: 2, ease: "easeOut" },
          boxShadow: { delay: 2, duration: 2, ease: "easeOut" },
        }}
      ></motion.div>{" "}
      <motion.div
        className="text"
        initial={{ x: 0, opacity: 0, scale: 0.3 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ delay: 2.5, duration: 2 }}
      >
        <h1>Benito.dev</h1>
        <ReactTyped
          strings={["Welcome to my part of the web, feel free to explore"]}
          typeSpeed={40}
          backSpeed={50}
          startDelay={4500}
        />
      </motion.div>{" "}
    </div>
  );
};

export default Home;
