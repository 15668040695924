import React from "react";
import { motion } from "framer-motion";
import Window from "../components/Window.js";
import "../App.css";
import "./WhoAmI.css";
import { Link } from "react-router-dom";
import {
  cProgrammingLogo,
  cPlusPlusLogo,
  cmakeLogo,
  wxWidgetsLogo,
  javascriptLogo,
  swiftLogo,
  gitIcon,
  reactLogo,
  htmlLogo,
  cssLogo,
} from "../helpers/icons";

const WhoAmI = () => {
  const calculateAge = () => {
    const birthday = new Date("2003-07-31");
    const today = new Date();

    let age = today.getFullYear() - birthday.getFullYear();

    const monthDiff = today.getMonth() - birthday.getMonth();
    const dayDiff = today.getDate() - birthday.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  return (
    <div className="whoami-container">
      <section className="hero-section">
        <motion.div
          className="hero-content-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="hero-content">
            <h1>Hi, I’m Bartek</h1>
            <h2> a passionate software engineer from Cracow.</h2>
            <p>
              I’m currently pursuing an Applied Computer Science degree at AGH
              Kraków UST. Welcome to my personal space where you can learn more
              about me and what I do!
            </p>
          </div>
          <div className="hero-image">
            <img src="assets/profile.jpeg" alt="Bartek" />
          </div>
        </motion.div>
      </section>

      {/* About Me Section */}
      <section className="about-me">
        <motion.div
          initial={{ x: "-100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 1.5 }}
        >
          <Window title="About Me">
            <p>
              Hey there! I’m Bartek, though you might have been expecting
              Benito. The tale behind that nickname is a bit of an enigma, even
              to me, but it has stuck around for years. As a {calculateAge()}
              -year-old software engineer, I’m deeply passionate about coding
              and tackling complex problems. My experience spans languages such
              as C, C++, and I’ve recently embraced web development while
              building this site. I’m always eager to dive into new technologies
              and explore innovative solutions.
            </p>
          </Window>
        </motion.div>
      </section>

      {/* Skills & Interests Section */}
      <section className="skills-interests">
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 2.0, duration: 1.5 }}
        >
          <Window title="Interests">
            <p>
              When I’m not coding, I enjoy a variety of activities that keep me
              energized and inspired:
            </p>
            <ul class="hobbies-list">
              <li>
                <strong>Reading</strong> - there's nothing as engaging as a good
                book - currently reading "Homo Deus" by Y.N. Harari
              </li>
              <li>
                <strong>Working out</strong> - Regular exercise helps me stay
                fit and focused, balancing out the hours spent in front of a
                screen.{" "}
              </li>
              <li>
                <strong>Cats</strong> - let's be honest, who doesn't like{" "}
                <motion.div
                  style={{ display: "inline-block", position: "relative" }}
                  whileHover="hover"
                  initial="rest"
                  animate="rest"
                >
                  <a
                    href="https://www.youtube.com/watch?v=dHoojSkODqE"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    those
                  </a>
                  <motion.span
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      height: "2px",
                      width: "100%",
                      backgroundColor: "#fab387",
                      transformOrigin: "left",
                      scaleX: 0,
                    }}
                    variants={{
                      hover: { scaleX: 1 },
                      rest: { scaleX: 0 },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.div>{" "}
                crazy fluffballs?
              </li>
              <li>
                <strong>Pineapple on pizza</strong> - a bit controversial, I
                know...
              </li>
            </ul>{" "}
          </Window>
        </motion.div>
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 3, duration: 1.5 }}
        >
          <Window title="Skills">
            <div className="grid-container">
              <div className="grid-item">
                <img src={cProgrammingLogo} alt="C" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img src={cPlusPlusLogo} alt="C++" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img
                  src={javascriptLogo}
                  alt="JavaScript"
                  width="50"
                  height="50"
                />
              </div>

              <div className="grid-item">
                <img src={swiftLogo} alt="Swift" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img src={gitIcon} alt="Git" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img src={reactLogo} alt="React" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img src={htmlLogo} alt="HTML" width="50" height="50" />
              </div>
              <div className="grid-item">
                <img src={cssLogo} alt="HTML" width="50" height="50" />
              </div>
            </div>
          </Window>
        </motion.div>
      </section>

      <section className="contact">
        <motion.div
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 4, duration: 1.5 }}
        >
          <Window title="Let's Connect">
            <p>
              I’m always excited to connect with like-minded individuals and
              explore new opportunities. You can find contact information{" "}
              <motion.div
                style={{ display: "inline-block", position: "relative" }}
                whileHover="hover"
                initial="rest"
                animate="rest"
              >
                <Link to="/contact" className="link">
                  here
                </Link>
                <motion.span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "2px",
                    width: "100%",
                    backgroundColor: "#fab387",
                    transformOrigin: "left",
                    scaleX: 0,
                  }}
                  variants={{
                    hover: { scaleX: 1 },
                    rest: { scaleX: 0 },
                  }}
                  transition={{ duration: 0.3 }}
                />
              </motion.div>
            </p>
          </Window>{" "}
        </motion.div>
      </section>
    </div>
  );
};

export default WhoAmI;
